import Vue from 'vue'
import VueRouter from 'vue-router'
// コンポーネント読み込み
import Contents from '@/views/Contents.vue'

Vue.use(VueRouter)

const routes = [
  { path: "/", component: Contents },
  { path: "/:hl", component: Contents },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

export default router
