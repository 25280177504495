var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
          'mt-7 mb-6 ml-6': _vm.$vuetify.breakpoint.mdAndDown,
          'mt-12 mb-8 ml-9': _vm.$vuetify.breakpoint.lgAndUp,
        }},[_c('v-row',{staticClass:"mb-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"auto"}},[_c('div',{class:{
          'text-h5 font-weight-bold': _vm.$vuetify.breakpoint.mdAndDown,
          'text-h3 font-weight-bold': _vm.$vuetify.breakpoint.lgAndUp,
        }},[_vm._v(" "+_vm._s(_vm.$t("header.title"))+" ")])]),_c('v-spacer')],1),_c('p',{class:{
      'text-subtitle-2 font-weight-bold': _vm.$vuetify.breakpoint.mdAndDown,
      'text-subtitle-1 font-weight-bold': _vm.$vuetify.breakpoint.lgAndUp,
    }},[_vm._v(" "+_vm._s(_vm.$t("header.description1"))),_c('br'),_vm._v(" "+_vm._s(_vm.$t("header.description2"))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }