<template>
  <v-container fluid class="pa-0">
    <v-toolbar>
      <v-img
        max-height="24"
        max-width="24"
        src="@/assets/logo.png"
        contain
        :class="{
          'ml-4 mr-5': $vuetify.breakpoint.smAndDown,
          'ml-15 mr-5': $vuetify.breakpoint.mdAndUp,
        }"
      ></v-img>
      <v-toolbar-title>App Color</v-toolbar-title>
      <v-spacer />
      <div
        v-if="$vuetify.breakpoint.smAndUp"
        class="d-flex align-center"
        style="width:200px"
      >
        <v-select
          :items="languages"
          v-model="selectedLanguageId"
          outlined
          item-text="lauguageName"
          item-value="id"
          @change="changeLanguage"
          hide-details="true"
          dense
        />
      </div>
    </v-toolbar>
    <v-container>
      <Header />
      <v-row no-gutters class="mb-15">
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 10">
          <v-card
            color="#E8F9FF"
            elevation="2"
            outlined
            :class="{
              'pl-8 pb-6 pt-2 pr-8': $vuetify.breakpoint.smAndDown,
              'pl-12 pb-6 pt-2 pr-8': $vuetify.breakpoint.lgAndUp,
            }"
          >
            <div>
              <p
                :class="{
                  'text-h6 font-weight-bold mb-1':
                    $vuetify.breakpoint.mdAndDown,
                  'text-h5 font-weight-bold mb-1': $vuetify.breakpoint.lgAndUp,
                }"
              >
                {{ $t("hint.title") }}
              </p>
            </div>
            <div>
              <p
                :class="{
                  'body-2 font-weight-bold mb-4': $vuetify.breakpoint.mdAndDown,
                  'caption font-weight-bold mb-4': $vuetify.breakpoint.lgAndUp,
                }"
              >
                {{ $t("hint.description") }}
              </p>
            </div>
            <div>
              <v-row no-gutters>
                <v-col cols="auto" class="mr-3 d-flex align-center">
                  <p
                    :class="{
                      'body-2 font-weight-bold mb-0':
                        $vuetify.breakpoint.mdAndDown,
                      'caption font-weight-bold mb-0':
                        $vuetify.breakpoint.lgAndUp,
                    }"
                  >
                    {{ $t("hint.answer") }}
                  </p>
                </v-col>
                <v-col cols="auto" class="mr-1  d-flex align-center">
                  <ColorRect radius="rgba(255,120,5,0.48)" />
                </v-col>
                <v-col cols="auto" class="d-flex align-center">
                  <p
                    :class="{
                      'body-2 font-weight-bold mb-0':
                        $vuetify.breakpoint.mdAndDown,
                      'caption font-weight-bold mb-0':
                        $vuetify.breakpoint.lgAndUp,
                    }"
                  >
                    {{ $t("hint.result") }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col
          lg="12"
          md="12"
          sm="12"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 10"
        >
          <!-- <v-col
        lg="10"
        md="12"
        sm="12"
        :cols="$vuetify.breakpoint.smAndDown ? 12 : 10"
      > -->
          <v-row no-gutters>
            <v-col
              lg="4"
              md="4"
              sm="6"
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
              :class="{
                'mr-0 mb-4': $vuetify.breakpoint.smAndDown,
                'mr-8': $vuetify.breakpoint.mdAndUp,
              }"
            >
              <v-card outlined min-height="315px">
                <CardTitle :title="$t('input.opacityTitle')" />

                <v-radio-group v-model="opacitySelected" class="pr-10 pl-1">
                  <v-radio :value="0" @click="onOpacitySelectedChanged">
                    <template v-slot:label>
                      <v-row no-gutters>
                        <v-col lg="10" md="10" sm="12" cols="10">
                          <div class="mb-11">
                            <v-text-field
                              ref="opacity10Form"
                              :label="$t('input.opacityOpacity10Label')"
                              v-model="opacity10"
                              :placeholder="
                                $t('input.opacityOpacity10Placeholder')
                              "
                              :rules="[opacity10RulesRequired]"
                              @blur="formatOpacity10"
                              :hint="$t('input.opacityOpacity10Hint')"
                              persistent-hint
                              outlined
                              maxlength="3"
                              type="number"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </v-radio>
                  <v-radio :value="1" @click="onOpacitySelectedChanged">
                    <template v-slot:label>
                      <v-row no-gutters>
                        <v-col lg="10" md="10" sm="12" cols="10">
                          <div>
                            <v-text-field
                              ref="opacityPercentForm"
                              :label="$t('input.opacityOpacityPercentLabel')"
                              v-model="opacityPercent"
                              :placeholder="
                                $t('input.opacityOpacityPercentPlaceholder')
                              "
                              :rules="[opacityPercentRulesRequired]"
                              @blur="adjustToPercent"
                              :hint="$t('input.opacityOpacityPercentHint')"
                              persistent-hint
                              outlined
                              maxlength="3"
                              suffix="%"
                              type="number"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-card>
            </v-col>
            <v-col
              lg="7"
              md="7"
              sm="9"
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 7"
              :class="{
                'mr-0': $vuetify.breakpoint.smAndDown,
                'mr-1': $vuetify.breakpoint.mdAndUp,
              }"
            >
              <v-card outlined min-height="315px">
                <CardTitle :title="$t('input.colorTitle')" />

                <v-radio-group v-model="colorSelected" class="pr-10 pl-1">
                  <v-radio :value="0" @click="onColorSelectedChanged">
                    <template v-slot:label>
                      <v-row no-gutters>
                        <v-col cols="4">
                          <div class="mb-11">
                            <v-text-field
                              ref="colorHEXForm"
                              :label="$t('input.colorHexLabel')"
                              v-model="colorHEX"
                              :placeholder="$t('input.colorHexPlaceholder')"
                              :rules="[colorHEXRulesRequired]"
                              @blur="formatHex"
                              :hint="$t('input.colorHexHint')"
                              persistent-hint
                              outlined
                              prefix="#"
                              maxlength="7"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </v-radio>
                  <v-radio :value="1" @click="onColorSelectedChanged">
                    <template v-slot:label>
                      <div>
                        <v-row no-gutters>
                          <v-col cols="3.5" class="mr-5">
                            <v-text-field
                              ref="colorRGB_RForm"
                              v-model="colorRGB_R"
                              :label="$t('input.colorRGBRLabel')"
                              :placeholder="$t('input.colorRGBRPlaceholder')"
                              @blur="formatRGB_R"
                              :rules="[colorRGBRulesRequired]"
                              :hint="$t('input.colorRGBRHint')"
                              persistent-hint
                              outlined
                              maxlength="3"
                              type="number"
                            />
                          </v-col>
                          <v-col cols="3.5" class="mr-5">
                            <v-text-field
                              ref="colorRGB_GForm"
                              v-model="colorRGB_G"
                              :label="$t('input.colorRGBGLabel')"
                              :placeholder="$t('input.colorRGBGPlaceholder')"
                              @blur="formatRGB_G"
                              :rules="[colorRGBRulesRequired]"
                              :hint="$t('input.colorRGBGHint')"
                              persistent-hint
                              outlined
                              maxlength="3"
                              type="number"
                            />
                          </v-col>
                          <v-col cols="3.5">
                            <v-text-field
                              ref="colorRGB_BForm"
                              v-model="colorRGB_B"
                              :label="$t('input.colorRGBBLabel')"
                              :placeholder="$t('input.colorRGBBPlaceholder')"
                              @blur="formatRGB_B"
                              :rules="[colorRGBRulesRequired]"
                              :hint="$t('input.colorRGBBHint')"
                              persistent-hint
                              outlined
                              maxlength="3"
                              type="number"
                            />
                          </v-col>
                        </v-row>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-card>
            </v-col>
          </v-row>

          <!-- <div class="d-flex justify-center mt-4 mb-5">
          <v-card flat tile width="300px" height="100px" />
        </div> -->
          <!-- <div class="d-flex justify-center mt-4 mb-5" id="myform" /> -->

          <!-- <div class="d-flex justify-center mb-16"> -->
          <div class="d-flex justify-center mt-4 mb-16">
            <v-btn
              x-large
              color="primary"
              elevation="2"
              depressed
              @click="handleClick"
            >
              {{ $t("result.button") }}
            </v-btn>
          </div>

          <v-card class="d-flex justify-center" flat tile>
            <v-card
              :class="{
                'd-flex align-center mr-2': $vuetify.breakpoint.mdAndDown,
                'd-flex align-center mr-7': $vuetify.breakpoint.lgAndUp,
              }"
              flat
              tile
            >
              <ColorRect :radius="radius" />
            </v-card>
            <v-card
              class="d-flex align-center mr-6"
              :width="$vuetify.breakpoint.smAndDown ? '210px' : '276px'"
              flat
              tile
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="mb-2">
                    <div class="text-h6 font-weight-bold d-flex justify-start">
                      {{ $t("result.android") }}
                    </div>
                  </v-list-item-title>
                  <v-list-item-title class="mb-1">
                    <v-card
                      color="#F2F2F2"
                      :width="$vuetify.breakpoint.smAndDown ? '210px' : '276px'"
                    >
                      <span
                        class="text-subtitle-1 font-weight-bold d-flex justify-start"
                        v-html="androidFormat"
                      />
                    </v-card>
                  </v-list-item-title>
                  <v-divider
                    :width="$vuetify.breakpoint.smAndDown ? '210px' : '276px'"
                  ></v-divider>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card class="d-flex align-center" flat tile>
              <v-btn
                class="mx-2"
                small
                fab
                dark
                color="primary"
                @click="onClickCopyAndroid"
              >
                <v-icon dark>
                  mdi-content-copy mdi-18px
                </v-icon>
              </v-btn>
            </v-card>
          </v-card>

          <v-card class="d-flex justify-center" flat tile>
            <!-- <v-card class="d-flex justify-center" flat tile> -->
            <v-card
              :class="{
                'd-flex align-center mr-2': $vuetify.breakpoint.mdAndDown,
                'd-flex align-center mr-7': $vuetify.breakpoint.lgAndUp,
              }"
              flat
              tile
            >
              <ColorRect :radius="radius" />
            </v-card>
            <v-card
              class="d-flex align-center mr-6"
              :width="$vuetify.breakpoint.smAndDown ? '210px' : '276px'"
              flat
              tile
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="mb-2">
                    <div class="text-h6 font-weight-bold d-flex justify-start">
                      {{ $t("result.iOS") }}
                    </div>
                  </v-list-item-title>
                  <v-list-item-title class="mb-1">
                    <v-card
                      color="#F2F2F2"
                      :width="$vuetify.breakpoint.smAndDown ? '210px' : '276px'"
                    >
                      <span
                        class="text-subtitle-1 font-weight-bold text-wrap d-flex justify-start"
                        v-html="iOSFormat"
                      />
                    </v-card>
                  </v-list-item-title>
                  <v-divider
                    :width="$vuetify.breakpoint.smAndDown ? '210px' : '276px'"
                  ></v-divider>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card class="d-flex align-center" flat tile>
              <v-btn
                class="mx-2"
                small
                fab
                dark
                color="primary"
                @click="onClickCopyIOS"
              >
                <v-icon dark>
                  mdi-content-copy mdi-18px
                </v-icon>
              </v-btn>
            </v-card>
          </v-card>

          <!-- Web -->
          <v-card class="d-flex justify-center mb-12" flat tile>
            <!-- <v-card class="d-flex justify-center" flat tile> -->
            <v-card
              :class="{
                'd-flex align-center mr-2': $vuetify.breakpoint.mdAndDown,
                'd-flex align-center mr-7': $vuetify.breakpoint.lgAndUp,
              }"
              flat
              tile
            >
              <ColorRect :radius="radius" />
            </v-card>
            <v-card
              class="d-flex align-center mr-6"
              :width="$vuetify.breakpoint.smAndDown ? '210px' : '276px'"
              flat
              tile
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="mb-2">
                    <div class="text-h6 font-weight-bold d-flex justify-start">
                      {{ $t("result.web") }}
                    </div>
                  </v-list-item-title>
                  <v-list-item-title class="mb-1">
                    <v-card
                      color="#F2F2F2"
                      :width="$vuetify.breakpoint.smAndDown ? '210px' : '276px'"
                    >
                      <span
                        class="text-subtitle-1 font-weight-bold text-wrap d-flex justify-start"
                        v-html="webFormat"
                      />
                    </v-card>
                  </v-list-item-title>
                  <v-divider
                    :width="$vuetify.breakpoint.smAndDown ? '210px' : '276px'"
                  ></v-divider>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card class="d-flex align-center" flat tile>
              <v-btn
                class="mx-2"
                small
                fab
                dark
                color="primary"
                @click="onClickCopyWeb"
              >
                <v-icon dark>
                  mdi-content-copy mdi-18px
                </v-icon>
              </v-btn>
            </v-card>
          </v-card>

          <!-- <div class="d-flex justify-center mb-12">
          <v-card flat tile width="336px" height="280px" />
        </div> -->
        </v-col>

        <!-- <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="auto" class="mb-12">
        <v-card flat tile width="160px" height="600px" />
      </v-col> -->
      </v-row>

      <v-snackbar v-model="snackbar" timeout="2000" color="primary" outlined>{{
        snackbarText
      }}</v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
import ColorRect from "./components/ColorRect.vue";
import Header from "./components/Header.vue";
import CardTitle from "./components/CardTitle.vue";
//import postscribe from "postscribe";

export default {
  components: {
    ColorRect,
    Header,
    CardTitle,
  },

  data: () => ({
    hl: "",
    radius: "rgba(0, 0, 0, 1.0)",
    opacitySelected: 0,
    opacity10: "",
    opacityPercent: "",
    colorSelected: 0,
    colorHEX: "",
    colorRGB_R: "",
    colorRGB_G: "",
    colorRGB_B: "",
    androidFormat: "#FF000000",
    iOSFormat:
      "Color(red: 0.0/255.0, green: 0.0/255.0, blue: 0.0/255.0, opacity: 1.0)",
    webFormat: "rgba(0, 0, 0, 1.00)",
    snackbar: false,
    snackbarText: "",
    languages: [
      { lauguageName: "English", id: 0 },
      { lauguageName: "日本語", id: 1 },
    ],
    selectedLanguageId: 0,
  }),

  created() {
    const hl = this.$route.params["hl"];
    if (hl == "ja") {
      this.hl = "ja";
      this.selectedLanguageId = 1;
    } else {
      this.hl = "en";
      this.selectedLanguageId = 0;
    }
  },

  methods: {
    onClickCopyAndroid() {
      var main = this;
      this.$copyText(this.androidFormat).then(function(e) {
        main.snackbarText =
          main.androidFormat + " " + main.$t("result.copyComplete");
        main.snackbar = true;
      });
    },

    onClickCopyIOS() {
      var main = this;
      this.$copyText(this.iOSFormat).then(function(e) {
        main.snackbarText =
          main.iOSFormat + " " + main.$t("result.copyComplete");
        main.snackbar = true;
      });
    },

    onClickCopyWeb() {
      var main = this;
      this.$copyText(this.webFormat).then(function(e) {
        main.snackbarText =
          main.webFormat + " " + main.$t("result.copyComplete");
        main.snackbar = true;
      });
    },

    opacity10RulesRequired(value) {
      if (this.opacitySelected != 0) {
        return true;
      } else {
        if (value == null || value.length === 0) {
          return this.$t("input.errorDecimalNumber");
        } else {
          return true;
        }
      }
    },

    opacityPercentRulesRequired(value) {
      if (this.opacitySelected != 1) {
        return true;
      } else {
        if (value == null || value.length === 0) {
          return this.$t("input.errorPercent");
        } else {
          return true;
        }
      }
    },

    colorHEXRulesRequired(value) {
      if (this.colorSelected != 0) {
        return true;
      } else {
        if (value == null || value.length === 0) {
          return this.$t("input.errorHexadecimal");
        } else {
          return true;
        }
      }
    },

    colorRGBRulesRequired(value) {
      if (this.colorSelected != 1) {
        return true;
      } else {
        if (value == null || value.length === 0) {
          return this.$t("input.errorDecimalNumber");
        } else {
          return true;
        }
      }
    },

    handleClick() {
      var androidOpacity = "";
      var iOSOpacity = "";
      var webOpacity = "";
      var androidColor = "";
      var iOSColor = "";
      var webColor = "";

      var previewOpacity = "";
      var previewRGB = "";

      switch (this.opacitySelected) {
        case 0:
          this.adjustToDecimalNumber(this.opacity10);
          if (this.opacity10 == "" || isNaN(this.opacity10)) {
            this.opacity10 = "255";
          }
          androidOpacity = this.formatToHexadecimalFromDecimalNumber(
            this.opacity10
          );
          iOSOpacity = this.formatToDecimalFromDecimalNumber(this.opacity10);
          webOpacity = this.formatToDecimalFromDecimalNumber(this.opacity10);

          previewOpacity = this.formatToDecimalFromHexadecimal(this.opacity10);
          break;
        case 1:
          this.adjustToPercent(this.opacityPercent);
          if (this.opacityPercent == "" || isNaN(this.opacityPercent)) {
            this.opacityPercent = "100";
          }
          androidOpacity = this.formatToHexadecimalFromPercent(
            this.opacityPercent
          );
          iOSOpacity = this.formatToPercentFromDecimalNumber(
            this.opacityPercent
          );
          webOpacity = this.formatToPercentFromDecimalNumber(
            this.opacityPercent
          );

          previewOpacity = this.formatToPercentFromDecimalNumber(
            this.opacityPercent
          );
          break;
      }
      switch (this.colorSelected) {
        case 0:
          this.formatHex(this.colorHEX);
          if (this.colorHEX == "") {
            this.colorHEX = "000000";
          }
          androidColor = `${this.colorHEX}`;

          var colorHexArray = this.colorHEX.split("");
          var colorHexIntArray = [];
          colorHexArray.forEach((value) => {
            colorHexIntArray.push(this.getHexadecimalInt(value));
          });

          var rDecimalNumber = colorHexIntArray[0] * 16 + colorHexIntArray[1];
          var gDecimalNumber = colorHexIntArray[2] * 16 + colorHexIntArray[3];
          var bDecimalNumber = colorHexIntArray[4] * 16 + colorHexIntArray[5];
          iOSColor = `red: ${rDecimalNumber}.0/255.0, green: ${gDecimalNumber}.0/255.0, blue: ${bDecimalNumber}.0/255.0`;
          webColor = `${rDecimalNumber}, ${gDecimalNumber}, ${bDecimalNumber}`;
          previewRGB = `${rDecimalNumber}, ${gDecimalNumber}, ${bDecimalNumber}`;
          break;
        case 1:
          if (this.colorRGB_R == "" || isNaN(this.colorRGB_R)) {
            this.colorRGB_R = "0";
          }
          if (this.colorRGB_G == "" || isNaN(this.colorRGB_G)) {
            this.colorRGB_G = "0";
          }
          if (this.colorRGB_B == "" || isNaN(this.colorRGB_B)) {
            this.colorRGB_B = "0";
          }
          var r = this.adjustToDecimalNumber(this.colorRGB_R);
          var g = this.adjustToDecimalNumber(this.colorRGB_G);
          var b = this.adjustToDecimalNumber(this.colorRGB_B);

          iOSColor = `red: ${r}.0/255.0, green: ${g}.0/255.0, blue: ${b}.0/255.0`;
          webColor = `${r}, ${g}, ${b}`;
          var rHex = this.formatToHexadecimalFromDecimalNumber(r);
          var gHex = this.formatToHexadecimalFromDecimalNumber(g);
          var bHex = this.formatToHexadecimalFromDecimalNumber(b);

          previewRGB = `${r}, ${g}, ${b}`;
          androidColor = `${rHex}${gHex}${bHex}`;
          break;
      }

      this.androidFormat = `#${androidOpacity}${androidColor}`;
      this.iOSFormat = `Color(${iOSColor}, opacity: ${iOSOpacity})`;
      this.webFormat = `rgba(${webColor}, ${webOpacity})`;
      this.radius = `rgba(${previewRGB} , ${previewOpacity})`;
    },

    formatToHexadecimalFromDecimalNumber(value) {
      var digit1 = Number(value) % 16;
      var digit2 = Math.floor(Number(value) / 16);

      return (
        String(this.getHexadecimalString(digit2)) +
        String(this.getHexadecimalString(digit1))
      );
    },

    formatToDecimalFromDecimalNumber(value) {
      var result = Number(value) / 255;
      return result.toFixed(2);
    },

    formatToHexadecimalFromPercent(value) {
      var x = Math.floor((Number(value) * 255) / 100);
      return this.formatToHexadecimalFromDecimalNumber(x);
    },

    formatToPercentFromDecimalNumber(value) {
      // %から小数への変換
      var result = Number(value) / 100;
      return result.toFixed(2);
    },

    formatToDecimalFromHexadecimal(value) {
      var result = Number(value) / 255;
      return result.toFixed(2);
    },

    onOpacitySelectedChanged() {
      switch (this.opacitySelected) {
        case 0:
          this.$refs.opacityPercentForm.resetValidation();
          break;
        case 1:
          this.$refs.opacity10Form.resetValidation();
          break;
      }
    },

    onColorSelectedChanged() {
      switch (this.colorSelected) {
        case 0:
          this.$refs.colorRGB_RForm.resetValidation();
          this.$refs.colorRGB_GForm.resetValidation();
          this.$refs.colorRGB_BForm.resetValidation();
          break;
        case 1:
          this.$refs.colorHEXForm.resetValidation();
          break;
      }
    },

    formatOpacity10() {
      this.opacity10 = this.adjustToDecimalNumber(this.opacity10);
    },

    formatRGB_R() {
      this.colorRGB_R = this.adjustToDecimalNumber(this.colorRGB_R);
    },

    formatRGB_G() {
      this.colorRGB_G = this.adjustToDecimalNumber(this.colorRGB_G);
    },

    formatRGB_B() {
      this.colorRGB_B = this.adjustToDecimalNumber(this.colorRGB_B);
    },

    formatHex() {
      let re = new RegExp("^[0-9A-F]+$");
      let isMatch = re.test(this.colorHEX);
      if (this.colorHEX.trim() == "" || !isMatch) {
        this.colorHEX = "";
      } else {
        var length = this.colorHEX.length;
        if (length < 6) {
          this.colorHEX = this.colorHEX.padEnd(6, "0");
        }
        length = this.colorHEX.length;
        if (length > 6) {
          this.colorHEX = this.colorHEX.substr(0, 6);
        }
      }
    },

    adjustToDecimalNumber(value) {
      if (value == "" || isNaN(value)) {
        // 数値ではない
        return "";
      } else {
        // 数値に変換
        var num = Number(value);
        if (num < 0) {
          return 0;
        } else if (num >= 255) {
          return 255;
        } else {
          return num;
        }
      }
    },

    adjustToPercent() {
      var value = this.opacityPercent;
      if (value == "" || isNaN(value)) {
        // 数値ではない
        this.opacityPercent = "";
      } else {
        // 数値に変換
        var num = Number(value);
        if (num < 0) {
          this.opacityPercent = 0;
        } else if (num >= 100) {
          this.opacityPercent = 100;
        } else {
          this.opacityPercent = num;
        }
      }
    },

    getHexadecimalString(value) {
      var result = "";
      switch (String(value)) {
        case "0":
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
          result = value;
          break;
        case "10":
          result = "A";
          break;
        case "11":
          result = "B";
          break;
        case "12":
          result = "C";
          break;
        case "13":
          result = "D";
          break;
        case "14":
          result = "E";
          break;
        case "15":
          result = "F";
          break;
      }

      return result;
    },

    getHexadecimalInt(value) {
      var result = 0;
      switch (String(value)) {
        case "0":
          result = 0;
          break;
        case "1":
          result = 1;
          break;
        case "2":
          result = 2;
          break;
        case "3":
          result = 3;
          break;
        case "4":
          result = 4;
          break;
        case "5":
          result = 5;
          break;
        case "6":
          result = 6;
          break;
        case "7":
          result = 7;
          break;
        case "8":
          result = 8;
          break;
        case "9":
          result = 9;
          break;
        case "A":
          result = 10;
          break;
        case "B":
          result = 11;
          break;
        case "C":
          result = 12;
          break;
        case "D":
          result = 13;
          break;
        case "E":
          result = 14;
          break;
        case "F":
          result = 15;
          break;
      }

      return result;
    },

    toHalfSize(value) {
      return String(value).replace(/[Ａ-Ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      });
    },

    toUpperCase(value) {
      return String(value).replace(/[a-z]/g, function(s) {
        return s.toUpperCase();
      });
    },

    changeLanguage(value) {
      switch (value) {
        case 0:
          this.hl = "en";
          break;
        case 1:
          this.hl = "ja";
          break;
      }
      this.$router.replace({ path: `/${this.hl}`});
      this.$router.go()
    },
  },



  computed: {},

  filters: {},

  watch: {
    opacity10(newValue) {
      this.opacity10 = this.toHalfSize(newValue);
    },

    opacityPercent(newValue) {
      this.opacityPercent = this.toHalfSize(newValue);
    },

    colorHEX(newValue) {
      var result = this.toHalfSize(newValue);
      if (result.indexOf("#") != -1) {
        this.colorHEX = this.toUpperCase(result.replace("#", ""));
      } else {
        this.colorHEX = this.toUpperCase(result);
      }
    },

    colorRGB_R(newValue) {
      this.colorRGB_R = this.toHalfSize(newValue);
    },

    colorRGB_G(newValue) {
      this.colorRGB_G = this.toHalfSize(newValue);
    },

    colorRGB_B(newValue) {
      this.colorRGB_B = this.toHalfSize(newValue);
    },
  },

  //async mounted() {
  mounted() {
    this.$i18n.locale = this.hl;

    document.title = this.$t("html.title");
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", this.$t("html.meta_description"));

    // let foo = document.createElement("script");
    // foo.src =
    //   "//i.socdm.com/sdk/js/adg-script-loader.js?id={48548}&targetID=targetID&displayid=1&adType=SP&async=false&tagver=2.0.0";
    // this.$refs.myform.appendChild(foo);

    // var script = `<script src="//i.socdm.com/sdk/js/adg-script-loader.js?id=48548&targetID=targetID&displayid=1&adType=SP&async=false&tagver=2.0.0"><\/script>`

    // postscribe("#myform", script, {
    //   done: function() {
    //     console.info("Dblclick script has been delivered.");
    //   },
    // });
  },
};
</script>
