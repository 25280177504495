<template>
  <canvas width="27" height="27" class="canvas"></canvas>
</template>

<script>
export default {
  props: {
    radius: {
      type: String,
    },
  },
  watch: {
    radius() {
      this.clearRect();
      this.draw();
    },
  },
  methods: {
    clearRect() {
      this.ctx.save();
      this.ctx.clearRect(0, 0, 27, 27);
      this.ctx.restore();
    },

    draw() {
      this.ctx.fillStyle = this.radius;
      this.ctx.beginPath();
      this.ctx.fillRect(0, 0, 27, 27);
      this.ctx.strokeStyle = "rgb(0, 0, 0)";
      this.ctx.strokeRect(0,0,27,27);
    },
  },
  mounted() {
    this.ctx = this.$el.getContext("2d");
    this.draw();
  },
};
</script>
