<template>
  <p class="text-subtitle-2 font-weight-bold mt-4 d-flex justify-center">
    {{ title }}
  </p>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
  },
};
</script>
